import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./utils/permission"
// 全局样式初始化
import './assets/style/base.css'
import "./assets/iconfont/iconfont.css"
// 引入加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 引入工具类
import tool from "./utils/tool"
import "./utils/watchLocalStorage"
// import "./utils/rem"
// 引入过滤器
import "./utils/filters"
// 表格自适应指令
import tableHeight from './utils/tableHeight/index';
Vue.use(tableHeight)
// 无限加载
import InfiniteLoad from "./components/public/InfiniteLoad"
Vue.component('loading', InfiniteLoad)
// 引入echarts
import * as echarts from 'echarts'
import "./utils/element"
Vue.config.productionTip = false;
import i18n from "@/lang/index";
let loading = null;
// 引入elementui中的MessageBox
import {MessageBox} from 'element-ui'
Vue.prototype.$confirm=MessageBox.confirm;
/* eslint-disable no-new */
router.beforeEach((to, from, next) => {

  NProgress.start();
  next();
});

router.afterEach(transition => {
  if (loading) {
    loading.close()
  }
  NProgress.done();
});
Vue.prototype.$echarts = echarts
Vue.prototype.$tool = tool

// if (process.env && process.env.VUE_APP_CONFIG == "show") {
//   store.commit("config/TOGGLE_SHOW_CONFIG", true)
// }
// console.log(process.env);
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
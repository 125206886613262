export default [
  {
    label: "序号",
    prop: "serial",
    minWidth: "60",
    isShow: true,
  },
  {
    label: "大棚编号",
    prop: "name",
    minWidth: "100",
    isShow: true,
    noData: '--'
  }, {
    label: "大棚面积(㎡)",
    prop: "area",
    minWidth: "150",
    isShow: true,
    noData: '--'
  }, {
    label: "种植户",
    prop: "farmer",
    minWidth: "80",
    isShow: true,
    noData: '暂无'
  }, {
    label: "归属",
    prop: "belong",
    minWidth: "120",
    isShow: true,
    noData: '暂无'
  }, {
    label: "经度",
    prop: "lon",
    minWidth: "100",
    isShow: true,
    noData: "--"
  }, {
    label: "纬度",
    prop: "lat",
    minWidth: "100",
    isShow: true,
    noData: "--"
  }, {
    label: "技术负责人",
    prop: "director",
    minWidth: "120",
    isShow: true,
    noData: '暂无'
  }, {
    label: "负责人联系方式",
    prop: "phone",
    minWidth: "150",
    isShow: true,
    noData: '暂无'
  }, {
    label: "是否装有物联设备",
    prop: "is_equip",
    minWidth: "150",
    isShow: true,
    noData: '--'
  }, {
    label: "备注",
    prop: "remark",
    minWidth: "200",
    isShow: true,
    noData: '暂无'
  },
]
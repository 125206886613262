import store from "../store/index"
import { reLoadName } from "../config/project"
var orignalSetItem = localStorage.setItem;
localStorage.setItem = function (key, newValue) {
    if (key == reLoadName && newValue == 1) {
        store.commit('SET_RELOAD', true)
    }
    // 要监听的key为approveCount
    var setItemEvent = new Event("setItemEvent");
    setItemEvent.newValue = newValue;
    setItemEvent.key = key;
    window.dispatchEvent(setItemEvent);
    orignalSetItem.apply(this, arguments);

}
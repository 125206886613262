import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import {
  getCookie,
  setCookie
} from "@/utils/cookie"

import {
  tokenName, reLoadName, receiverName
} from "../config/project"
import {
  data
} from "autoprefixer";
import skin from "./modules/skin"
import permission from "./modules/permission"
import config from "./modules/config"
import { setItem, getItem } from "@/utils/localStorage";

Vue.use(Vuex);
let userMessObj = (function () {
  return getCookie('userMessObj') ? JSON.parse(getCookie('userMessObj')) : {}
})()
let isLogin = (function () {
  return getCookie(tokenName) ? true : false
})()
let reLoad = (function () {
  let init = getCookie(reLoadName);
  if (init) {
    return Number(init) == 1 ? true : false
  } else {
    return false
  }
})()
let currentReceiver = (function () {
  return getItem(receiverName) ? JSON.parse(getItem(receiverName)) : {}
})()
export default new Vuex.Store({
  state: {
    isLogin, // 登录状态
    userMessObj,
    sideNavOpen: true, // 侧边栏
    currentGreen: '', // 当前大棚
    greenList: [], // 大棚列表
    loading: false,
    isReload: reLoad,
    defaultGreenIds: [1, 3, 4, 13],
    currentReceiver
  },
  mutations: {
    SET_LOGIN_STATUS(state, val) {
      state.isLogin = val
    },
    SET_USERINFO(state, val) {
      state.userMessObj = val
    },
    TOGGLE_SIDE_OPEN(state) {
      state.sideNavOpen = !state.sideNavOpen
    },
    SET_SIDE_OPEN(state, val) {
      state.sideNavOpen = val
    },
    SET_CURRENT_GREEN(state, val) {
      state.currentGreen = val;
    },
    SET_GREEN_LIST(state, val) {
      state.greenList = val
    },
    TOGGLE_LOADING(state, val) {
      state.loading = val
    },
    SET_RELOAD(state, val) {
      state.isReload = val
    },
    SET_RECEIVER(state, val) {
      setItem(receiverName, JSON.stringify(val));
      state.currentReceiver = val;
    }
  },
  actions: {
    async login({
      commit
    }, loginInfo) {

      // await router.selfAddRoutes([...meunNav])
      return new Promise((resolve, reject) => {
        setCookie(tokenName, '222222222')

        resolve(true)
      })
    },
    setReload(store, val = 0) {
      setItem(reLoadName, val);
    },
  },
  getters: {
    userMessObj(state) {
      return state.userMessObj
    },
    sideNavOpen(state) {
      return state.sideNavOpen
    },
    currentGreen(state) {
      return state.currentGreen
    },
    greenList(state) {
      return state.greenList
    },
    loading(state) {
      return state.loading
    },
    isReload(state) {
      return state.isReload
    },
    defaultGreenIds(state) {
      return state.defaultGreenIds
    },
    menuList() {
      return permission.state.menuList
    },
    routes() {
      return permission.state.routes
    },
    // Modules/config    getters
    isShowConfig() {
      return config.state.isShowConfig
    },
    deviceInfo() {
      return config.state.deviceInfo
    },
    lang() {
      return config.state.lang
    },
    model() {
      return config.state.model
    }
  },
  modules: {
    skin,
    permission,
    config
  },
});

import { setItem } from "../../utils/localStorage"
const store = {
  state: {
    routes: [], // 动态路由
    menuList: [], //侧边栏
    topLevel:'', // navMode == 'sideTop' 时顶部的一级菜单选中项
  },
  mutations: {
    SET_ROUTES(state, routes) {
      setItem('apiFullRoute', JSON.stringify(routes))
      state.routes = routes
    },
    SET_MENU(state, menuList) {
      state.menuList = menuList
    },
    SET_TOP_LEVEL(state, val) {
      state.topLevel = val
    },
  },
  getters: {
    menuList(state) {
      return state.menuList
    },
    routes(state) {
      return state.routes
    }
  }

}
export default {
  namespaced: true,
  ...store
}
export default [
  {
    label: "序号",
    prop: "serial",
    minWidth: "60",
    isShow: true,
  }, {
    label: "设备编号",
    prop: "device_name",
    minWidth: "100",
    isShow: true,
    noData: "----"
  }, {
    label: "设备名称",
    prop: "device_type_name",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "设备品牌",
    prop: "brand",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "设备型号",
    prop: "model",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "所在大棚",
    prop: "greenhouse_name",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "设备图片",
    prop: "images",
    minWidth: "100",
    isShow: true,
    noData: require("../../assets/images/public/default_sensor.png")
  }, {
    label: "经度",
    prop: "lon",
    minWidth: "100",
    isShow: true,
    noData: "--"
  }, {
    label: "纬度",
    prop: "lat",
    minWidth: "100",
    isShow: true,
    noData: "--"
  }, {
    label: "传感器1",
    prop: "sensor1",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "传感器2",
    prop: "sensor2",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "传感器3",
    prop: "sensor3",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "传感器4",
    prop: "sensor4",
    minWidth: "100",
    isShow: true,
    noData: "暂无"
  }, {
    label: "安装时间",
    prop: "install_at",
    minWidth: "150",
    isShow: true,
    noData: "----/--/--"
  }, {
    label: "创建时间",
    prop: "created_at",
    minWidth: "150",
    isShow: true,
    noData: "----/--/--"
  }, {
    label: "最后更新时间",
    prop: "updated_at",
    minWidth: "150",
    isShow: true,
    noData: "----/--/--"
  }, {
    label: "备注",
    prop: "remark",
    minWidth: "200",
    isShow: true,
    noData: "----/--/--"
  },
]
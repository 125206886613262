
const store = {
    state: {
        skinList: [], // 皮肤列表
        skinShow: false
    },
    mutations: {
        SET_SKIN_LIST(state, val) {
            state.skinList = val;
        },
        TOGGLE_SHOW_SKIN(state) {
            state.skinShow = !state.skinShow
        }
    },
    getters: {
        skinList(state) {
            return state.skinList
        },
        skinShow(state) {
            return state.skinShow
        }
    }

}
export default {
    namespaced: true,
    ...store
}
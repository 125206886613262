import lang from "@/lang/index.js"
// 验证字母加数字  
// /login --- username password
// /receiver/editWorkModel --- username password accessName accessSet
// /receiver/editPassword --- password  
const validValueNumAndLetter = (val) => {
    return /^[A-Za-z0-9]+$/.test(val)
}
const validValueNumAndLetterAnd_ = (val) => {
    return /^[A-Za-z0-9_]+$/.test(val)
}
// 验证正整数
// /receiver/editWorkModel --- deviceId PDOP differAge post
// /receiver/editSatelliteSet --- cutoffAngle
const validPositiveInteger = (val) => {
    return /^\+?[1-9]\d*$/.test(val)
}
// ip校验
// /receiver/editWorkModel --- ip
const validIp = (val) => {
    return /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/.test(val)
}
// 度分秒 经度校验
// /receiver/editWorkModel --- lng
const validDFMLng = (val) => {
    // ^(((-|\+)?(\d|[1-9]\d|1[0-7]\d)°[0-5]?[0-9]′[0-5]?[0-9](?:\.\d{1,7})?″)$)|(180[°]0[′]0[″]?$)
    return /^(((-|\+)?(\d|[1-9]\d|1[0-7]\d)°[0-5]?[0-9]′[0-5]?[0-9](?:\.\d{1,7})?″)$)|(180[°]0[′]0[″]?$)/.test(val)
}
// 度分秒 纬度校验
// /receiver/editWorkModel --- lat
const validDFMLat = (val) => {
    // ^(((-|\+)?(\d|[1-8]\d)°[0-5]?[0-9]′[0-5]?[0-9](?:\.\d{1,7})?″)$)|(90[°]0[′]0[″]?$)
    return /^(((-|\+)?(\d|[1-8]\d)°[0-5]?[0-9]′[0-5]?[0-9](?:\.\d{1,7})?″)$)|(90[°]0[′]0[″]?$)/.test(val)
}
// 度 经度校验
// /receiver/editWorkModel --- lng
const validLng = (val) => {
    // console.log(val);
    return /^((-|\+)?(?:1[0-7]\d(\.\d{1,20})?|180(\.0{1,20})?|0?\d{1,2}(\.\d{1,20})?))$/.test(val)
}

// 度 纬度校验
// /receiver/editWorkModel --- lat
const validLat = (val) => {
    // console.log(val);
    return /^((-|\+)?(?:90(\.0{1,20})?|[1-8]?\d(\.\d{1,20})?|0(\.0{1,20})?))$/.test(val)
}
// 度分 经度校验
// /receiver/editWorkModel --- lng
const validDFLng = (val) => {
    return /^(((-|\+)?(\d|[1-9]\d|1[0-7]\d)°[0-5]?[0-9](?:\.\d{1,7})?′)$)|(180[°]0[′]?$)/.test(val)
}
// 度分 纬度度校验
// /receiver/editWorkModel --- lat
const validDFLat = (val) => {
    return /^(((-|\+)?(\d|[1-8]\d)°[0-5]?[0-9](?:\.\d{1,7})?′)$)|(90[°]0[′]0[′]?$)/.test(val)
}
// 校验字母数字加汉字
// /receiver/editWorkModel --- fileName
const validAll = (val) => {
    return /^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(val)
}
const validateIP = (rule, value, callback) => {
  console.log(lang.t("label"), "langgggggg")
    const ip =
      /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    if (value && !ip.test(value)) {
      return callback(new Error(lang.t("dialog.postSet.ipCorrect")));
    } else {
      callback();
    }
  };
  const validatePort = (rule, value, callback) => {
    const port = /^\d{1,}$/;
    if (!port.test(value)) {
      return callback(new Error(lang.t("dialog.postSet.numPort")));
    } else {
      callback();
    }
  };
  const validateUserNamePWD = (rule, value, callback) => {
    console.log(rule);
    const fh = new RegExp(
      "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
    );
    let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (!value) {
      if (rule.field == "username") {
        callback(new Error(lang.t("dialog.postSet.account")));
      } else {
        callback(new Error(lang.t("dialog.postSet.pwd")));
      }
    } else if (hz.test(value) || fh.test(value)) {
      console.log(value);
      callback(new Error(lang.t("dialog.postSet.numAndLetter")));
    } else {
      callback();
    }
  };
  const validateJRD = (rule, value, callback) => {
    const fh = new RegExp(
      "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
    );
    let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (hz.test(value) || fh.test(value)) {
      console.log(value);
      callback(new Error(lang.t("dialog.postSet.numAndLetter")));
    } else {
      // if (this.form && this.form.repassword !== "") {
      //   this.$refs.form.validateField("repassword");
      // }
      callback();
    }
  };
  const validateInterval = (rule, value, callback) => {
    // console.log(Number(value) < 1 || Number(value) > 7200, "????????");
    const num = /^[1-9]\d*$/;
    if (!value) {
      callback(new Error(lang.t("dialog.postSet.enterInterval")));
    } else if (Number(value) < 1 || Number(value) > 3600 || !num.test(value)) {
      callback(new Error(lang.t("dialog.postSet.3600")));
    } else {
      callback();
    }
  };
export {
    validValueNumAndLetter,
    validPositiveInteger,
    validIp,
    validDFMLng,
    validDFMLat,
    validDFLng,
    validDFLat,
    validLng,
    validLat,
    validAll,
    validValueNumAndLetterAnd_,
    validatePort,
    validateUserNamePWD,
    validateJRD,
    validateInterval,
    validateIP
}
import Vue from "vue";
import i18n from "@/lang/index";
import ElementLocale from 'element-ui/lib/locale'
// 引入element-ui框架
import {
  Loading,
  Form,
  FormItem,
  Input,
  InputNumber,
  Row,
  Col,
  Tabs,
  TabPane,
  Checkbox,
  CheckboxGroup,
  Button,
  Avatar,
  Popover,
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  Pagination,
  Message,
  Carousel,
  CarouselItem,
  MessageBox,
  RadioGroup,
  Radio,
  RadioButton,
  Switch,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
  Tooltip,
  Image,
  DatePicker,
  Empty,
  Upload,
  ColorPicker,
  Tree,
  Drawer,
  Badge,
  Slider,
  Progress,
  Cascader
} from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(Loading.directive);
Vue.use(Cascader);
Vue.use(Slider);
Vue.use(Progress);
Vue.use(Form);
Vue.use(Tree);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Avatar);
Vue.use(Popover);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Carousel);
Vue.use(CarouselItem)
Vue.use(RadioGroup);
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(Switch)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Image)
Vue.use(DatePicker)
Vue.use(Empty)
Vue.use(Upload)
Vue.use(ColorPicker)
Vue.use(InputNumber)
Vue.use(CheckboxGroup)
Vue.use(Drawer)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Badge)

Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox

ElementLocale.i18n((key, value) => i18n.t(key, value))
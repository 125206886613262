/* 统一过滤器入口 */
import Vue from 'vue';

Vue.filter('angleToDirection', function (str) {

    if (!str) {
        return '---'
    }
    let res = ''
    let number = Number(str)
    if (number == 0) {
        res = '北风'
    } else if (number > 0 && number < 45) {
        res = '北偏东北'
    } else if (number == 45) {
        res = '东北风'
    } else if (number > 45 && number < 90) {
        res = '东北偏东'
    } else if (number == 90) {
        res = '东风'
    } else if (number > 90 && number < 135) {
        res = '东偏东南'
    } else if (number == 135) {
        res = '东南风'
    } else if (number > 135 && number < 180) {
        res = '东南偏南'
    } else if (number == 180) {
        res = '南风'
    } else if (number > 180 && number < 225) {
        res = '南偏西南'
    } else if (number == 225) {
        res = '西南风'
    } else if (number > 225 && number < 270) {
        res = '西南偏西'
    } else if (number == 270) {
        res = '西风'
    } else if (number > 270 && number < 315) {
        res = '西偏西北'
    } else if (number == 315) {
        res = '西北风'
    } else {
        res = '西北偏北'
    }
    return res
})
 const getCookie = function (c_name) {
   if (document.cookie.length > 0) {
     let c_start = document.cookie.indexOf(c_name + "=");
     if (c_start != -1) {
       c_start = c_start + c_name.length + 1;
       let c_end = document.cookie.indexOf(";", c_start);
       if (c_end == -1) {
         c_end = document.cookie.length;
       }
       return unescape(document.cookie.substring(c_start, c_end));
     }
   }
   return "";
 }
 const setCookie = function (c_name, value, expiredays, type = 'day') {
   let exdate = new Date();


   if (type === 'day') {
     //  天
     exdate.setDate(exdate.getDate() + expiredays);
   } else {
     //  小时
     exdate.setDate(exdate.getTime() + expiredays * 60 * 60 * 1000);
   }

   document.cookie = c_name + "=" + escape(value) +
     ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString()) + "; path=/;";
 }
 const delCookie = function (name) {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
 }
 export {
   getCookie,
   setCookie,
   delCookie
 }
// 获得本地缓存的内容
export function getItem(key) {
  return localStorage.getItem(key)
}
// 设置本地缓存的内容
export function setItem(key, item) {
  return localStorage.setItem(key, item)
}
// 移除本地缓存的内容
export function removeItem(key) {
  return localStorage.removeItem(key)
}

import {
  langFlag
} from "../../config/project"
import { setItem, getItem } from "@/utils/localStorage";
let lang = (function () {
  if (getItem(langFlag)) {
    return getItem(langFlag)
  } else {
    setItem(langFlag, 'zh')
    return 'zh'
  }
})()
const store = {
  state: {
    isShowConfig: false,
    deviceInfo: {
      type: "PC",
      innerWidth: 0,
    },
    lang,
    model:""
  },
  mutations: {
    TOGGLE_SHOW_CONFIG(state, val) {
      state.isShowConfig = val;
    },
    SET_DEVICE_INFO(state, val) {
      state.deviceInfo = val;
    },
    SET_LANG(state, val) {
      setItem(langFlag, val);
      state.lang = val;
    },
    SET_MODEL(state, val) {
      state.model = val;
    },
  },
  actions: {
    getDeviceInfo({
      commit
    }) {
      let deviceInfo = {
        type: "PC",
        innerWidth: 0
      }
      if (window.innerWidth <= 480) {
        deviceInfo.type = 'MOBIE'
        deviceInfo.innerWidth = window.innerWidth
      } else {
        deviceInfo.type = 'PC'
        deviceInfo.innerWidth = window.innerWidth
      }
      commit("SET_DEVICE_INFO", deviceInfo)
    },
    setLanguage({ commit }, val) {
      commit("SET_LANG", val);
      window.location.reload()
    }
  }
}
export default {
  namespaced: true,
  ...store
}
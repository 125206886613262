import Vue from 'vue'
import VueI18n from 'vue-i18n'

import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementESLocale from 'element-ui/lib/locale/lang/es'
import elementTRLocale from 'element-ui/lib/locale/lang/tr-TR'
import elementRULocale from 'element-ui/lib/locale/lang/ru-RU'
import elementENLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from './zh.json'
import enLocale from './en.json'
import ruLocale from './ru-RU.json'
import trLocale from './tr-TR.json'
import esLocale from './es.json'
import { langFlag } from "../config/project"
Vue.use(VueI18n) // vue使用i8n模块 引入本地

const messages = {
  zh: {
    ...elementZhLocale,
    ...zhLocale
  },
  'ru-RU': {
    ...elementRULocale,
    ...ruLocale
  },
  'tr-TR': {
    ...elementTRLocale,
    ...trLocale
  },
  es: {
    ...elementESLocale,
    ...esLocale
  },
  en: {
    ...elementENLocale,
    ...enLocale
  },
}

// zh-cn 中文   en-uk  英文  ru俄文
export function getLanguage(defaultLang) {
  // const chooseLanguage = Cookies.get('language')
  let localLang = localStorage.getItem(langFlag)
  if (localLang === null) {
    defaultLang = defaultLang || 'zh'   // 如果没有传入参数，则默认zh
    return defaultLang
  } else {
    return localLang
  }


}

// 创建国际化实例
const i18n = new VueI18n({
  // locale: Cookies.get('language'),
  locale: getLanguage(),
  silentTranslationWarn: true,
  messages: messages // 语言包
})

export default i18n
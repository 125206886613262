<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.listenerResize();
    this.$nextTick(() => {
      this.banCtrlMouseWheel();
      window.addEventListener("resize", this.listenerResize, false);
    });
  },
  watch: {
    $route: {
      // handler(nVal){
      // 	let str = ''
      // 	if(nVal.name =='login'){
      // 		str = this.$t(`label.login.login_btn`)
      // 	}else{
      // 		str = this.$t(`routes.${nVal.name}`)
      // 	}
      // 	document.title = str  || nVal.meta.title;
      // },
      handler(nVal, oVal) {
        let str = "";
        if (nVal.name == "login") {
          str = this.$t(`label.login.login_btn`);
        } else {
          if (
            nVal.matched.filter(
              (f) => f.name == "receiver" || f.name == "status" || f.name == "config"
            ).length
          ) {
            this.$store.commit("config/SET_MODEL", "config");
            // this.$store.commit(
            //   "SET_CURRENT_SN",
            //   sessionStorage.getItem("currentSn")
            // );
            // this.$store.commit(
            //   "SET_RECEIVER",
            //   JSON.parse(sessionStorage.getItem(receiverName))
            // );
          } else {
            this.$store.commit("config/SET_MODEL", "");
            str = nVal.name ? this.$t(`routes.${nVal.name}`) : '北斗接收机远程管理平台';
          }
        }
        if (str || nVal.meta.title) {
          // console.log(str, nVal.meta.title, nVal, "title")
          // document.title = str || nVal.meta.title;
          document.title = str || this.$t(`routes.${nVal.meta.pRoute}`) || nVal.meta.title;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    listenerResize() {
      this.$store.dispatch("config/getDeviceInfo");
    },
    banCtrlMouseWheel() {
      window.addEventListener(
        "mousewheel",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        { passive: false }
      );

      //firefox
      window.addEventListener(
        "DOMMouseScroll",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  height: 100%;
  background-color: #e6e7ea;
  /* background-color: $main_color; */
}

.container {
  height: 100%;
}
</style>

import greenhouseIndex from "./tableItem/greenhouseIndex"
import deviceSensor from "./tableItem/deviceSensor"
import meteorological from "./tableItem/meteorological"
import monitor from "./tableItem/monitor"
import irrigate from "./tableItem/irrigate"
import motor from "./tableItem/motor"
const tableItem = {
  // 大棚管理
  greenhouseIndex,
  // 传感器管理
  deviceSensor,
  // 气象站管理
  meteorological,
  // 摄像头管理
  monitor,
  // 灌溉阀门管理
  irrigate,
  // 卷膜电机管理
  motor
}


export default tableItem
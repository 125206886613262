import tool from "../utils/tool"
import { handelStaticRoutes } from "./tools/generateRouter"
import { meunNav } from "../config/project"
import store from '@/store/index.js'
import {
  getItem,
  setItem
} from "@/utils/localStorage"
const baseRouter = [
  {
    path: "/",
    name: "index",
    component: () => import('@/layout/container'),
    meta: {
      title: '智能农机API开放平台'
    },
  }, {
    path: "/index",
    redirect: '/'
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/layout/login/index'),
    meta: {
      title: '请登录'
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import('@/layout/404/index'),
    meta: {
      title: '404'
    }
  },
  {
    path: "*",
    redirect: "/404"
  },
];
const getRoutes = (params = []) => {
  if (tool.getType(params) !== 'Array' || !params.length) {
    let temp = getItem("apiFullRoute") ? JSON.parse(getItem("apiFullRoute")) : [];
    const { routes, orgRoutes, meunList } = handelStaticRoutes(temp);
    console.log(routes);
    if (routes.length && orgRoutes.length && meunList.length) {
      setItem("apiFullRoute", orgRoutes)
      store.commit('permission/SET_ROUTES', orgRoutes)
      store.commit('permission/SET_MENU', meunList)
      baseRouter[0].children = routes
      baseRouter[0].redirect = {
        name: routes.filter(f => !f.meta.hidden)[0] ? routes.filter(f => !f.meta.hidden)[0].name : 'index'
      }
    }
    return baseRouter
  }
  baseRouter[0].children = params
  baseRouter[0].redirect = {
    name: params.filter(f => !f.meta.hidden)[0].name
  }
  return baseRouter
}

export {
  getRoutes
}
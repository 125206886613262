const title = "后台模板后台模板后台模板";
const logo = require("../assets/images/public/logo.png");
// const baseUrl = 'http://114.215.129.119:18100';
// const baseUrl = 'http://10.100.20.35:13016'; // sl
// const baseUrl = "http://114.215.129.119:23999";  
// const baseUrl = "http://nxa34kegc963.allynav.cn:9000/receiver"; // 测试
const baseUrl = "https://bdrmp.allynav.cn/api"; // 正式新华为云

// 默认皮肤
const defaultSkin = [
  {
    label: "默认",
    topColor: "#ffffff",
    topBg: ["#539D56", "#357437"],
    sideColor: "#265628",
    sideBg: ["#ffffff", "#ffffff"],
    isDefault: true,
    checked: true,
  },
];

// 管理端侧边栏
const meunNav = [
  {
    route: "positionMap",
    meta: {
      title: "位置地图",
      icon: require("../assets/images/public/positionMap.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      // oneChildrenHidden: false,
    },
    children: [
      {
        route: "positionMap",
        meta: {
          title: "位置地图",
          icon: require("../assets/images/public/positionMap.png"),
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  {
    route: "equipManage",
    meta: {
      title: "设备管理",
      icon: require("../assets/images/public/equipManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      // oneChildrenHidden: false
    },
    children: [
      {
        route: "receiverList",
        meta: {
          title: "设备管理",
          icon: require("../assets/images/public/equipManage.png"),
          sort: 3,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  {
    route: "receiver",
    meta: {
      title: "接收机管理",
      type: "config",
      icon: require("../assets/images/public/receiver.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
    },
    children: [
      // {
      //   id: 1,
      //   pid: "receiver",
      //   title: "接收机信息",
      //   route: "info",
      //   icon: "",
      //   sort: 2,
      //   hidden: false,
      //   isDetail: false,
      // },
      {
        route: "info",
        meta: {
          title: "接收机信息",
          icon: "",
          sort: 2,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "updata",
        meta: {
          title: "固件更新",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },

  {
    route: "status",
    meta: {
      title: "接收机状态",
      type: "config",
      icon: require("../assets/images/public/status.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      oneChildrenHidden: false,
    },
    children: [
      // {
      //   route: "locationInfo",
      //   meta: {
      //     title: "位置信息",
      //     icon: "111111",
      //     sort: 1,
      //     hidden: false,
      //     isDetail: false,
      //   },
      // },
      {
        route: "satellites",
        meta: {
          title: "卫星信息",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "activity",
        meta: {
          title: "活动",
          icon: "",
          sort: 3,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  {
    route: "config",
    meta: {
      title: "接收机配置",
      type: "config",
      icon: require("../assets/images/public/config.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
    },
    children: [
      {
        route: "mode_new",
        meta: {
          title: "工作模式",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "satelliteConfig",
        meta: {
          title: "卫星设置",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "nmea",
        meta: {
          title: "NMEA消息",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "antennaSet",
        meta: {
          title: "天线高",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "postSet",
        meta: {
          title: "I/O配置",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
      {
        route: "order",
        meta: {
          title: "指令配置",
          icon: "",
          sort: 1,
          hidden: false,
          isDetail: false,
        },
      },
    ],
  },
  // {
  //   route: "IOConfig",
  //   meta: {
  //     title: "I/O配置",
  //     icon: require("../assets/images/public/IOConfig.png"),
  //     sort: 1,
  //     hidden: false,
  //     isDetail: false,
  //     // oneChildrenHidden: false,
  //   },
  //   children: [
  //     {
  //       route: "postSet",
  //       meta: {
  //         title: "I/O配置",
  //         icon: require("../assets/images/public/IOConfig.png"),
  //         sort: 1,
  //         hidden: false,
  //         isDetail: false,
  //       },
  //     },
  //   ],
  // },
  {
    route: "user",
    meta: {
      title: "用户管理",
      icon: require("../assets/images/public/userManage.png"),
      sort: 1,
      hidden: false,
      isDetail: false,
      // oneChildrenHidden: false,
    },
    children: [
      {
        route: "userList",
        meta: {
          title: "用户管理",
          icon: require("../assets/images/public/userManage.png"),
          sort: 1,
          hidden: false,
          hiddenSide: false,
          isDetail: false,
        },
      },
      {
        route: "center",
        meta: {
          title: "个人中心",
          icon: "111111",
          sort: 1,
          hidden: true,
          hiddenSide: false,
          isDetail: false,
        },
      },
    ],
  },
  // {
  //   route: "center",
  //   meta: {
  //     title: "个人中心",
  //     icon: "111111",
  //     sort: 1,
  //     hidden: false,
  //     isDetail: false,
  //     // oneChildrenHidden: false,
  //   },
  //   children: [
  //     {
  //       route: "center",
  //       meta: {
  //         title: "个人中心",
  //         icon: "111111",
  //         sort: 1,
  //         hidden: false,
  //         hiddenSide: false,
  //         isDetail: false,
  //       },
  //     },
  //   ],
  // },
  // {
  //   route: "download",
  //   meta: {
  //     title: "下载管理",
  //     icon: "111111",
  //     sort: 1,
  //     hidden: false,
  //     isDetail: false,
  //   },
  //   children: [
  //     {
  //       route: "data",
  //       meta: {
  //         title: "数据下载",
  //         icon: "111111",
  //         sort: 1,
  //         hidden: false,
  //         isDetail: false,
  //       },
  //     },
  //   ]
  // },
  // {
  //   route: "other",
  //   meta: {
  //     title: "其他",
  //     icon: "111111",
  //     sort: 1,
  //     hidden: false,
  //     oneChildrenHidden: false,
  //     isDetail: false,
  //   },
  //   children: [
  //     {
  //       route: "loginSet",
  //       meta: {
  //         title: "登录设置",
  //         icon: "111111",
  //         sort: 1,
  //         hidden: false,
  //         isDetail: false,
  //       },
  //     },
  //   ]
  // },
];

// 导航栏模式
const navMode = "default"; // default 只有侧边栏  sideTop 侧边和顶部都有
// 布局模式
const layoutMode = "navFull"; // navFull  顶部导航栏通栏   sideFull 侧边导航栏通栏
// 是否开启页面缓存
const useRouteCache = true;

// 表格自定义列
const tableItemName = "temp_tableItem";
// 本地皮肤缓存名称
const skinName = "temp_skin";
// 本地token缓存名称
const tokenName = "temp_token";
// 本地重载缓存
const reLoadName = "temp_reload";
// 本地记录当前选择的接收机
const receiverName = "currentReceiver";
// 本地翻译标识
const langFlag = "localLangFlag";
// 本地翻译可选项
const langEnum = [
  {
    label: "中文",
    type: "zh",
    isCheck: false,
    icon: require("../assets/images/public/zh.png"),
    alias: "中文",
  },
  {
    label: "English",
    type: "en",
    isCheck: false,
    icon: require("../assets/images/public/en.png"),
    alias: "英文",
  },
  {
    label: "русский язык",
    type: "ru-RU",
    isCheck: false,
    icon: require("../assets/images/public/ru.png"),
    alias: "俄语",
  },
  {
    label: "Türkçe",
    type: "tr-TR",
    isCheck: false,
    icon: require("../assets/images/public/tr.png"),
    alias: "土耳其语",
  },
  {
    label: "Español",
    type: "es",
    isCheck: false,
    icon: require("../assets/images/public/es.png"),
    alias: "西班牙语",
  },
];
export {
  title,
  baseUrl,
  meunNav,
  tokenName,
  skinName,
  defaultSkin,
  reLoadName,
  navMode,
  tableItemName,
  useRouteCache,
  layoutMode,
  logo,
  langFlag,
  langEnum,
  receiverName,
};
